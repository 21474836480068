import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { templateEditActions, templateEditFeature } from './template-edit';
import { Store } from '@ngrx/store';
import { map, switchMap, tap, withLatestFrom } from 'rxjs';
import { ExecuteTemplateData, FusionClient, TemplateParams } from '@ssi/fusion';
import { templateParametersFeature } from '../template-parameters/template-parameters';
import { buildTemplateParams } from '../template-parameters/template-parameters.types';

export const buildCsvEffect = createEffect((actions$ = inject(Actions), store = inject(Store), fusionClient = inject(FusionClient)) => {
  return actions$.pipe(
    ofType(templateEditActions.buildCsvInit),
    withLatestFrom(store.select(templateEditFeature.selectTemplateEditState),
      store.select(templateParametersFeature.selectTemplateParametersState)),
    switchMap(([_, editState, paramState]) => {
      const templateParams: TemplateParams = buildTemplateParams([ ...paramState.normalParameterItemList,
        ...paramState.userParameterItemList ], true);
      const data: ExecuteTemplateData = {
        templateId: editState.template?.templateId || '',
        parameters: templateParams,
        companyUuid: editState.companyUuid
      };
      return fusionClient.buildCsv(data);
    }),
    tap(response => {
      if (!response.ok) return;
      window.open(response.data!.preSignedUrl, '_self');
    }),
    map(() => templateEditActions.buildCsvSuccess())
  );

}, { functional: true });
