import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { templateEditActions, templateEditFeature } from './template-edit';
import { forkJoin, map, of, switchMap, withLatestFrom } from 'rxjs';
import { GetLogEventsCommand } from '@aws-sdk/client-cloudwatch-logs';
import { CloudwatchGroup } from '../../app.constants';
import { AwsClientFactory } from '../../services/aws-client-factory.service';

export const fetchLogsEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store), clientFactory = inject(AwsClientFactory)) => {
    return actions$.pipe(
      ofType(templateEditActions.loadLogsInit),
      withLatestFrom(store.select(templateEditFeature.selectTemplateEditState)),
      switchMap(([_, editState]) => Promise.all([editState, clientFactory.buildCloudwatchClient()])),
      switchMap(([editState, cloudwatchClient]) => {
        const command = new GetLogEventsCommand({
          logGroupIdentifier: CloudwatchGroup,
          logStreamName: editState.queryResponse?.context.logStreamName,
          startTime: editState.queryLogs.queryStart,
          endTime: editState.queryLogs.queryEnd + 5000
        });
        return forkJoin({
          editState: of(editState),
          logResponse: cloudwatchClient.send(command).catch(() => ({ events: [] }))
        });
      }),
      map(data => {
        const filtered = (data.logResponse.events || [])
          .map(evt => evt.message || '')
          .filter(msg => msg.includes(data.editState.queryResponse!.context.awsRequestId))
          .map(msg => {
            const requestId = data.editState.queryResponse!.context.awsRequestId || '';
            const idx = msg.indexOf(`${requestId}\tINFO`);
            return idx > 0 ? msg.substring(idx + 42) : msg;
          });
        return templateEditActions.loadLogsSuccess({ logs: filtered });
      })
    );
  },
  { functional: true }
);
