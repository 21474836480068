import { Injectable } from '@angular/core';
import { CloudWatchLogsClient } from '@aws-sdk/client-cloudwatch-logs';
import { fromCognitoIdentityPool, fromTemporaryCredentials } from '@aws-sdk/credential-providers';
import { S3Client } from '@aws-sdk/client-s3';
import { AwsCredentialIdentityProvider } from '@aws-sdk/types';

import { AuthTokenService } from './auth-token.service';
import { IdentityPool } from '../app.constants';

@Injectable({ providedIn: 'root' })
export class AwsClientFactory {
  private static readonly ClientLifetime = 15 * 60; // in seconds, equals 15 minutes
  private wrapperList: ClientWrapper[] = [];

  constructor(private readonly authTokenService: AuthTokenService) { }

  async buildCloudwatchClient(): Promise<CloudWatchLogsClient> {
    return this.buildClient('cloudwatch');
  }

  buildS3Client(): Promise<S3Client> {
    return this.buildClient<S3Client>('s3');
  }

  private getIdentityProvider(account: AccountType, idToken: string): AwsCredentialIdentityProvider {
    const loginKey = `cognito-idp.${IdentityPool.region}.amazonaws.com/${IdentityPool.userPoolId}`;
    const skyProvider = fromCognitoIdentityPool({
      identityPoolId: IdentityPool.identityPoolId,
      clientConfig: { region: IdentityPool.region },
      logins: { [loginKey]: idToken },
      accountId: IdentityPool.AgvanceSkyAccountId
    });
    if (account === 'AgvanceSky') return skyProvider;

    return fromTemporaryCredentials({
      masterCredentials: skyProvider,
      clientConfig: { region: IdentityPool.region },
      params: {
        RoleArn: IdentityPool.AgvanceAccountRoleArn,
        RoleSessionName: `AgvSession${Date.now()}`,
        DurationSeconds: AwsClientFactory.ClientLifetime
      }
    });
  }

  private async buildClient<T>(type: ClientType): Promise<T> {
    const cachedClient = this.getCachedClient<T>(type);
    if (cachedClient) return cachedClient;

    const idToken = await this.authTokenService.getIdToken();
    const account = type === 'cloudwatch' ? 'Agvance' : 'AgvanceSky';
    const credentials = this.getIdentityProvider(account, idToken);
    const ClientClass = type === 'cloudwatch' ? CloudWatchLogsClient : S3Client;
    const client = new ClientClass({ region: IdentityPool.region, credentials });
    return this.cacheClient<T>(type, <T>client);
  }

  private getCachedClient<T>(type: ClientType): T | undefined {
    this.wrapperList = this.wrapperList.filter(w => w.expiresAt > Date.now());
    const wrapper = this.wrapperList.find(w => w.type === type);
    return wrapper ? <T>wrapper.client : undefined;
  }

  private cacheClient<T>(type: ClientType, client: T): T {
    const expiresAt = Date.now() + (AwsClientFactory.ClientLifetime*1000);
    this.wrapperList.push(<any>{ type, expiresAt, client });
    return client;
  }
}

type ClientWrapper = CloudwatchWrapper | S3Wrapper;
type AccountType = 'Agvance' | 'AgvanceSky';
type ClientType = 'cloudwatch' | 's3';

interface CloudwatchWrapper {
  type: 'cloudwatch';
  expiresAt: number;
  client: CloudWatchLogsClient;
}

interface S3Wrapper {
  type: 's3';
  expiresAt: number;
  client: S3Client;
}
