import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from "@ngrx/store";
import { unsavedChangesAction } from "../store";
import { FusionResponse, PatchTemplateData, TemplateModel } from "@ssi/fusion";
import { produce } from "immer";
import { Ace, SkyPgDatabases } from "../../app.constants";
import { enterTemplateEditRouteEffect } from "./enter-template-edit-route.effect";
import { loadTemplateInitEffect } from "./load-template-init.effect";
import { loadSavedCompanyUuidEffect } from "./load-saved-company-uuid.effect";
import { runQueryEffect } from "./run-query.effect";
import { patchTemplateEffect } from "./patch-template.effect";
import { releaseTemplateEffect } from "./release-template.effect";
import { loadAceConfigEffect } from "./load-ace-config.effect";
import { updateCompanyUuidEffect } from "./update-company-uuid.effect";
import { AceEditorConfig, TemplateEditState, TemplateEditTabInfo } from "./template-edit.types";
import { fetchLogsEffect } from './fetch-logs.effect';
import { buildCsvEffect } from './build-csv.effect';

export const templateEditActions = createActionGroup({
  source: 'Template Edit',
  events: {
    'Load Template Init': props<{ templateId: string, userId: string }>(),
    'Load Template Success': props<{ template: TemplateModel }>(),
    'Company Uuid Success': props<{ companyUuid: string }>(),
    'Company Uuid Update': props<{ companyUuid: string }>(),
    'Run Query Init': emptyProps(),
    'Run Query Success': props<{ response: FusionResponse<any> }>(),
    'Change Tab': props<{ event: KeyboardEvent }>(),
    'Tab Clicked': props<{ index: number }>(),
    'Template Changes': props<{ data: PatchTemplateData }>(),
    'Patch Template Init': emptyProps(),
    'Patch Template Success': props<{ template: TemplateModel }>(),
    'Release Init': props<{ templateId: string }>(),
    'Release Success': props<{ template: TemplateModel }>(),
    'Load Ace Config Init': emptyProps(),
    'Load Ace Config Success': props<{ config: AceEditorConfig }>(),
    'Load Logs Init': emptyProps(),
    'Load Logs Success': props<{ logs: string[] }>(),
    'Build Csv Init': emptyProps(),
    'Build Csv Success': emptyProps()
  }
});

export const templateEditTabInfo: TemplateEditTabInfo = {
  'content': { name: 'Content', shortcut: '1', index: 0 },
  'parameters': { name: 'Parameters', shortcut: '2', index: 1 },
  'type': { name: 'Type', shortcut: '3', index: 2 },
  'security': { name: 'Security', shortcut: '4', index: 3 },
  'results': { name: 'Results', shortcut: '5', index: 4 },
  'logs': { name: 'Logs', shortcut: '6', index: 5 },
};

const defaultTemplateEditState: TemplateEditState = {
  template: undefined,
  runningQuery: false,
  companyUuid: undefined,
  queryResponse: undefined,
  selectedTabIndex: templateEditTabInfo['content'].index,
  tabInfo: templateEditTabInfo,
  aceEditorConfig: {
    keyboard: Ace.defaultKeyboard,
    sqlMode: 'sqlserver',
    readonly: false
  },
  skyDatabaseList: SkyPgDatabases.map(db => db.name).sort(),
  changes: undefined,
  queryLogs: { queryStart: 0, queryEnd: 0, logs: [], fetching: false },
  buildingCsvDownload: false
};

export const templateEditFeature = createFeature({
  name: 'templateEdit',
  reducer: createReducer(
    defaultTemplateEditState,
    on(unsavedChangesAction, () => {
      return defaultTemplateEditState;
    }),
    on(templateEditActions.loadTemplateInit, (state) => {
      return produce(state, draft => {
        draft.queryResponse = undefined;
        draft.selectedTabIndex = templateEditTabInfo['content'].index;
        draft.runningQuery = false;
      });
    }),
    on(templateEditActions.loadTemplateSuccess, (state, { template }) => {
      if (!template) return state;
      return produce(state, draft => {
        draft.template = template;
      });
    }),
    on(templateEditActions.patchTemplateSuccess, (state, { template }) => {
      if (!template) return state;
      return produce(state, draft => {
        draft.template = template;
        draft.changes = undefined;
      });
    }),
    on(templateEditActions.runQueryInit, state => {
      return produce(state, draft => {
        draft.runningQuery = true;
        draft.queryLogs = { queryStart: Date.now(), queryEnd: 0, logs: [], fetching: false };
      });
    }),
    on(templateEditActions.companyUuidSuccess, (state, { companyUuid }) => {
      return produce(state, draft => { draft.companyUuid = companyUuid; });
    }),
    on(templateEditActions.runQuerySuccess, (state, { response }) => {
      return produce(state, draft => {
        draft.queryResponse = response;
        draft.runningQuery = false;
        draft.selectedTabIndex = templateEditTabInfo['results'].index;
        draft.queryLogs.queryEnd = Date.now();
      });
    }),
    on(templateEditActions.changeTab, (state, { event }) => {
      return produce(state, draft => {
        if (!event.ctrlKey) return;
        const foundTab = Object.values(state.tabInfo).find(info => info.shortcut === event.key);
        if (!foundTab) return;
        draft.selectedTabIndex = foundTab.index;
      });
    }),
    on(templateEditActions.tabClicked, (state, { index }) => {
      return produce(state, draft => { draft.selectedTabIndex = index; });
    }),
    on(templateEditActions.releaseSuccess, (state, { template }) => {
      return produce(state, draft => {
        if (!draft.template) draft.template = template;
        draft.template.releasedAt = template.releasedAt;
      });
    }),
    on(templateEditActions.loadAceConfigSuccess, (state, { config }) => {
      return produce(state, draft => { draft.aceEditorConfig = config; });
    }),
    on(templateEditActions.templateChanges, (state, { data }) => {
      return produce(state, draft => {
        if (state.template!.releasedAt > 0) return;
        draft.changes = state.changes !== undefined ? { ...state.changes, ...data } as PatchTemplateData : data;
      });
    }),
    on(templateEditActions.loadLogsInit, (state) => {
      return produce(state, draft => {
        draft.queryLogs.fetching = true;
      });
    }),
    on(templateEditActions.loadLogsSuccess, (state, { logs }) => {
      return produce(state, draft => {
        draft.queryLogs.fetching = false;
        draft.queryLogs.logs = logs;
      });
    }),
    on(templateEditActions.buildCsvInit, (state) => {
      return produce(state, draft => {
        draft.buildingCsvDownload = true;
      });
    }),
    on(templateEditActions.buildCsvSuccess, (state) => {
      return produce(state, draft => {
        draft.buildingCsvDownload = false;
      });
    }),
  ),
  extraSelectors: ({ selectTemplate, selectSkyDatabaseList, selectChanges }) => ({
    skyDatabaseName: createSelector(selectTemplate, selectSkyDatabaseList, (template, dbList) => {
      if (!template) return dbList[0];
      const found = SkyPgDatabases.find(db => db.dev === template.metadata.skyData?.devDatabase);
      return found ? found.name : dbList[0];
    }),
    templateDataWithUnsavedChanges: createSelector(selectTemplate, selectChanges, (template, changes) => {
      const changeData = changes || {};
      return { ...template, ...changeData } as TemplateModel;
    }),
  })
});

export const templateEditEffects = { enterTemplateEditRouteEffect,
  loadTemplateInitEffect, loadSavedCompanyUuidEffect, runQueryEffect,
  patchTemplateEffect, releaseTemplateEffect, loadAceConfigEffect,
  updateCompanyUuidEffect, fetchLogsEffect, buildCsvEffect
};
