import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { PostSignInComponent } from './pages/post-sign-in/post-sign-in.component';
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Environment } from "./app.constants";
import { AuthTokenService } from './services/auth-token.service';
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FusionClient, FusionConfig, FusionFactory, InMemoryCache, SyncCache } from "@ssi/fusion";
import { BrowserStorage, BrowserLocation, BrowserWindow } from "./app.models";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { userEffects, userFeature } from "./store/user/user";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PostSignOutComponent } from './pages/post-sign-out/post-sign-out.component';
import { sharedEffects } from './store/shared.effects';
import { routerReducer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { templateListEffects, templateListFeature } from "./store/template-list/template-list";
import { templateEditEffects, templateEditFeature } from "./store/template-edit/template-edit";
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from "@angular/material/dialog";
import { templateParametersEffects, templateParametersFeature } from "./store/template-parameters/template-parameters";
import { analyticsUsageEffects, analyticsUsageFeature } from "./store/analytics-usage/analytics-usage";
import { MatMenuModule } from "@angular/material/menu";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PostSignInComponent,
    PostSignOutComponent,
    ConfirmationDialogComponent,
  ],
  imports: [
    EffectsModule.forRoot(templateListEffects, userEffects, templateEditEffects, sharedEffects,
      templateParametersEffects, analyticsUsageEffects),
    StoreModule.forRoot({
      templateList: templateListFeature.reducer,
      user: userFeature.reducer,
      templateEdit: templateEditFeature.reducer,
      templateParameters: templateParametersFeature.reducer,
      analyticsUsage: analyticsUsageFeature.reducer,
      router: routerReducer
    }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatTooltipModule
  ],
  providers: [
    { provide: BrowserStorage, useValue: window.localStorage },
    { provide: BrowserLocation, useValue: window.location },
    { provide: BrowserWindow, useValue: window },
    {
      provide: FusionClient,
      useFactory: (authTokenService: AuthTokenService) => {
        const config: FusionConfig = {
          application: 'fusion-web',
          environment: Environment,
          getIdTokenFn: () => {
            const altTokenKey = 'cognito_alt_token';
            const altToken = localStorage.getItem(altTokenKey);
            if (!altToken) {
              localStorage.setItem(altTokenKey, '');
            }
            return altToken ? Promise.resolve(altToken) : authTokenService.getIdToken();
          }
        };
        const factory = new FusionFactory(config);
        return factory.buildFusionClient();
      },
      deps: [AuthTokenService]
    },
    {
      provide: SyncCache,
      useValue: new InMemoryCache()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
